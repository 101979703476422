import "styles/pages/product.scss"

import React, { useState } from "react"
import { Link } from "gatsby"
import ReactPlayer from "react-player/lazy"

import Layout from "components/layout"
import Seo from "components/seo"
import PageHeader from "components/PageHeader/PageHeader"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import Button from "components/Button/Button"

const Product = ({ pageContext }) => {
  const { data } = pageContext
  const title = data.title
  const [typeTab, setTypeTab] = useState(1)
  const [videoPlay, setVideoPlay] = useState(false)

  return (
    <Layout>
      <Seo
        title={data.seo.title ? data.seo.title : title}
        description={data.seo.metaDesc && data.seo.metaDesc}
        ogImage={
          data?.seo?.opengraphImage?.sourceUrl
            ? data?.seo.opengraphImage?.sourceUrl
            : data?.featuredImage?.node?.sourceUrl
        }
      />
      <PageHeader title={title} />
      <Breadcrumbs title={title} parent="Vørur" />

      <section
        className="product-top-baner"
        style={{
          backgroundImage: `url('${
            data?.featuredImage && data?.featuredImage?.node?.sourceUrl
          }')`,
        }}
      ></section>

      <section className={`product-content product-content--${data.slug}`}>
        {data.acfProduct.productBackgroundShape && (
          <img
            className="img-fluid product-content__shape"
            src={data.acfProduct.productBackgroundShape.sourceUrl}
            alt=""
          />
        )}

        <div className="container-fluid">
          {data.acfProduct.productLogo && (
            <div className="product-content__logo">
              <img
                className="img-fluid"
                src={data.acfProduct.productLogo.sourceUrl}
                alt={title}
              />
            </div>
          )}

          <div className="row justify-content-center">
            {data.acfProduct.productDescriptionLeft && (
              <div
                className={
                  data.acfProduct.productDescriptionRight
                    ? "col-lg-5 col-sm-6"
                    : "col-lg-9 col-sm-12"
                }
              >
                <div
                  className="product-content__description"
                  dangerouslySetInnerHTML={{
                    __html: data.acfProduct.productDescriptionLeft,
                  }}
                />
              </div>
            )}
            <div className="col-lg-5 col-sm-6">
              {data.acfProduct.productDescriptionRight && (
                <div
                  className="product-content__description"
                  dangerouslySetInnerHTML={{
                    __html: data.acfProduct.productDescriptionRight,
                  }}
                />
              )}
            </div>
          </div>

          {data.acfProduct.productTypes && (
            <div className="product-content__types">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-sm-6">
                  <div className="product-content__types-header">VEL VØRU</div>
                  <div className="product-content__types-list">
                    {data.acfProduct.productTypes.map((item, index) => (
                      <button
                        className={typeTab === index + 1 ? "current" : ""}
                        key={index}
                        onClick={() => setTypeTab(index + 1)}
                      >
                        {item.productTypeTitle}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  {data.acfProduct.productTypes.map(
                    (item, index) =>
                      typeTab === index + 1 && (
                        <div
                          className="product-content__types-content"
                          key={index}
                        >
                          <h3>{data.title}</h3>
                          <hr />
                          {item.productTypeDescription &&
                            item.productTypeDescription}
                          {item.productCompositions && (
                            <div className="product-content__types-table">
                              {item.productCompositions.map((item, index) => (
                                <p key={index}>
                                  {item.productCompositionTitle}
                                  <span>{item.productCompositionValue}</span>
                                </p>
                              ))}
                            </div>
                          )}
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          )}

          {data.acfProduct.productContentImage && (
            <div className="product-content__image">
              <img
                className="img-fluid"
                src={data.acfProduct.productContentImage.sourceUrl}
                alt={title}
              />
            </div>
          )}

          {data.acfProduct.productVideoUrl && (
            <div className="product-content__video">
              <div className="product-content__video-embed">
                {!videoPlay && data.acfProduct.productVideoPoster && (
                  <div
                    className="product-content__video-poster"
                    style={{
                      backgroundImage: `url('${
                        data.acfProduct.productVideoPoster &&
                        data.acfProduct.productVideoPoster.sourceUrl
                      }')`,
                    }}
                  ></div>
                )}
                {!videoPlay && (
                  <button
                    className="product-content__video-play"
                    onClick={() => setVideoPlay(true)}
                  >
                    Play
                  </button>
                )}
                <div className="embed-container">
                  <ReactPlayer
                    url={data.acfProduct.productVideoUrl}
                    width="100%"
                    heigth="100%"
                    playing={videoPlay}
                    controls={true}
                    muted={false}
                  />
                </div>
              </div>

              <div className="product-content__video-description">
                <h2>{data.title}</h2>
                {data.acfProduct.productVideoDescription && (
                  <p>{data.acfProduct.productVideoDescription}</p>
                )}
              </div>
            </div>
          )}
        </div>
      </section>

      {data.acfProduct.productRelatedRecipies && (
        <section className={`product-related product-related--${data.slug}`}>
          <div className="container-fluid">
            <h3>Uppskriftir</h3>
            <div className="row">
              {data.acfProduct.productRelatedRecipies
                .slice(0, 3)
                .map((item, index) => (
                  <div className="col-md-4" key={index}>
                    <Link to={`/${item.slug}`} className="recipies__tile">
                      <div
                        className="recipies__tile-image"
                        style={{
                          backgroundImage: `url('${item?.featuredImage?.node?.sourceUrl}')`,
                        }}
                      ></div>
                      <p className="recipies__tile-title">{item.title}</p>
                    </Link>
                  </div>
                ))}
            </div>
            <div className="product-related__cta">
              <Button to="/uppskriftir/">Fleiri UPPSKRIFTIR</Button>
            </div>
          </div>
        </section>
      )}
    </Layout>
  )
}

export default Product
